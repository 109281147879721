import {
  Component,
  Input,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { IVerifyDeviceForm } from '@desquare/interfaces';
import { Maybe } from '@designage/gql';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDialogCloseReason, GQLErrorTypes } from '@desquare/enums';
import {
  ChannelService,
  CurrentUserService,
  DeviceDataService,
  ToasterService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { lastValueFrom } from 'rxjs';
import { BaseDialogComponent } from '@desquare/components/common/src/base-dialog/base-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceFormComponent } from '../device-form/device-form.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { DevicesStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    BaseDialogComponent,
    DeviceFormComponent,
    LoaderComponent,
  ],
  selector: 'app-device-create-dialog',
  template: `<app-base-dialog
    headerText="{{ isHideNameInput ? 'PROVISION_SCREEN' : 'CREATE_DEVICE' }}"
  >
    <!-- loader -->
    @if (loading) {
      <app-loader [message]="loaderMessage"></app-loader>
    } @else {
      <!-- body -->
      <div class="modal-body overflow-auto" style="max-height:70vh">
        <app-device-form
          [hidden]="loading"
          [profileId]="profileId"
          [channelId]="channelId"
          [areExtraButtonsHidden]="areExtraButtonsHidden"
          [deviceName]="deviceName"
          [isHideChannelSelection]="isHideChannelSelection"
          [isHideNameInput]="isHideNameInput"
          [country]="country"
          [isInvalidCode]="isInvalidCode"
          (valid)="isFormValid = $event"
          (pristine)="isFormPristine = $event"
          (values)="values = $event"
        ></app-device-form>
      </div>

      <!-- footer -->
      <div class="modal-footer">
        @if (showBackButton) {
          <button type="button" class="btn btn-primary" (click)="back()">
            {{ 'BACK' | translate }}
          </button>
        }
        <div class="push-right">
          <button
            type="button"
            class="btn btn-outline-warning me-2"
            (click)="dismiss()"
          >
            {{ 'CANCEL' | translate }}
          </button>

          <button
            [disabled]="!isFormValid"
            type="button"
            [class.btn-outline-success]="isFormPristine"
            [class.btn-success]="!isFormPristine"
            class="btn"
            (click)="submit()"
          >
            {{ 'PROVISION' | translate }}
          </button>
        </div>
      </div>
    }
    <!-- footer -->
  </app-base-dialog> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceCreateDialogComponent {
  devicesStore = inject(DevicesStore);
  channelService = inject(ChannelService);

  @Input() areExtraButtonsHidden = false;
  @Input() deviceName!: Maybe<string>;

  channelId!: Maybe<string>;
  profileId = inject(CurrentUserService).getCurrentProfileId();
  isFormValid!: boolean;
  isFormPristine!: boolean;
  isHideChannelSelection!: boolean;
  isHideNameInput = false;
  showBackButton!: boolean;
  values?: IVerifyDeviceForm;
  country!: string;

  // hash verification
  isInvalidCode = false;

  //loader
  loading: boolean = false;
  loaderMessage?: string | undefined;

  constructor(
    public modal: NgbActiveModal,
    private deviceService: DeviceDataService,
    private toasterService: ToasterService,
  ) {}

  dismiss() {
    this.modal.dismiss(this.values);
  }

  back() {
    this.modal.close(FormDialogCloseReason.BACK);
  }

  submit() {
    if (!this.values) return;

    this.loading = true;
    this.loaderMessage = 'PROVISIONING_DEVICE';

    // if channelId (input variable) has value
    // and if values.channelId (reactive form variable) does not have channelId
    // use the input variable instead
    if (this.channelId && !this.values.channelId) {
      this.values.channelId = this.channelId;
    }

    this.validateThenProvisionDevice(this.values);
  }

  validateThenProvisionDevice(values: IVerifyDeviceForm) {
    if (!this.profileId)
      return console.error('validateThenProvisionDevice: no profileId');

    const provisionDeviceInput =
      this.deviceService.toProvisionDeviceInput(values);

    lastValueFrom(this.deviceService.provisionDevice(provisionDeviceInput))
      .then(({ data }) => {
        if (data && data.provisionDevice.isSuccessful) {
          // this.getDevices(); // refresh view not needed here

          const device = data.provisionDevice.device;

          if (device?.id) {
            this.channelService.refreshChannels();
            this.toasterService.success('PROVISIONING_DEVICE_SUCCESS');

            // close modal
            this.modal.close(device);
          }
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
        }
        this.loading = false;
      })
      .catch((error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.log('validateThenProvisionDevice: ', gqlError);
          if (
            gqlError.extensions &&
            gqlError.extensions.code === GQLErrorTypes.INTERNAL_SERVER_ERROR
          ) {
            this.toasterService.error('PROVISIONING_DEVICE_ERROR');
          } else {
            this.toasterService.handleGqlError(gqlError);

            if (gqlError.extensions?.code === 'NOT_FOUND_ERROR') {
              this.isInvalidCode = true;
            }
          }
        });
        this.loading = false;
      });
  }
}
