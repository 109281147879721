@if (loading) {
  <app-loader [message]="loaderMessage"></app-loader>
} @else {
  <form [formGroup]="deviceForm" class="row">
    @if (!isHideNameInput) {
      <div class="row py-2">
        <div class="col form-group">
          <label class="form-label">{{ 'NAME' | translate }}*</label>
          @if (nameRequired) {
            <span class="error">
              {{ 'NAME_IS_REQUIRED' | translate }}
            </span>
          }
          <input
            type="text"
            name="name"
            class="form-control form-control-dark"
            formControlName="name"
            (keyup)="onKey('name')"
          />
        </div>
        <div class="col form-group">
          <label class="form-label"
            >{{ 'VERIFICATION_CODE' | translate }}*</label
          >
          @if (isInvalidCode) {
            <span class="error">
              {{ 'INVALID_CODE' | translate }}
            </span>
          }
          <div class="input-group">
            <input
              type="text"
              formControlName="verificationHash"
              name="hash"
              class="form-control form-control-dark"
              (keyup)="onKey('verificationHash')"
            />
          </div>
        </div>
      </div>
    }
    <div class="row py-2">
      <div class="col-3 form-group">
        <label class="form-label">{{ 'SCREEN_ORIENTATION' | translate }}</label>
        <div ngbDropdown>
          <input
            type="button"
            class="form-control form-control-dark dropdown"
            ngbDropdownToggle
            formControlName="orientation"
          />
          <div
            ngbDropdownMenu
            class="form-control form-control-dark shadowed-box text-white"
          >
            @for (orientation of orientations; track orientation) {
              <span
                ngbDropdownItem
                class="form-control form-control-dark dropdown-items"
                (click)="updateOrientation(orientation)"
                >{{ orientation }}</span
              >
            }
          </div>
        </div>
      </div>
      <div class="col-9 form-group">
        <label
          class="form-label"
          ngbTooltip="{{ 'SELECT_TIMEZONE_TOOL' | translate }}"
          placement="top"
          >{{ 'SELECT_TIMEZONE' | translate }}</label
        >
        <div class="d-flex">
          <designage-typeahead
            class="flex-grow-1 position-relative"
            [items]="timezones"
            [defaultItem]="newTimeZone"
            [placeHolderText]="'SELECT_TIMEZONE' | translate"
            [noMatchedItemText]="noMatchedItemText"
            [control]="timeZoneFormControl"
            (selectItem)="updateTimezone($event)"
          ></designage-typeahead>
          <button
            class="btn btn-sm btn-outline-primary ms-2"
            (click)="getProfileTimezone()"
            [ngbTooltip]="
              profileSettingsService.getProfileTimezoneValue().timezone
            "
          >
            {{ 'USE_PROFILE_SETTING' | translate }}
          </button>
        </div>
      </div>
    </div>
    @if (!isHideChannelSelection) {
      <div class="row py-2">
        <div class="col form-group">
          <label class="form-label" for="channel"
            >{{ 'CONNECT_DEVICE_TO_CHANNEL' | translate }}*</label
          >
          @if (
            deviceForm && deviceForm.controls['channelId'].hasError('required')
          ) {
            <span class="error">
              {{ 'CHANNEL_IS_REQUIRED' | translate }}
            </span>
          }
          <div class="input-group">
            <select
              name="channel"
              class="form-select form-select-dark"
              formControlName="channelId"
            >
              <option disabled selected [ngValue]="null">
                {{ 'SELECT_A_CHANNEL' | translate }}
              </option>
              @for (channel of channels(); track channel.id) {
                <option [ngValue]="channel.id">
                  {{ channel.name }}
                </option>
              }
            </select>
          </div>
        </div>
      </div>
    }
    <!-- LOCATION FORM -->
    <div class="row py-2">
      <div class="col form-group">
        <label class="form-label" for="location"
          >{{ 'LOCATION' | translate }}*</label
        >
        <app-location-tab-selection
          [profileId]="profileId ?? ''"
          [parentFormGroup]="deviceForm"
        />
      </div>
    </div>
  </form>
}
